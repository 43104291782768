import React, { useContext } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Button } from 'components/common';
import dev from 'assets/illustrations/skills.svg';
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles';

export const Projects = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper id="projects">
      <SkillsWrapper as={Container}>
        <Details theme={theme}>
          <h1>Beneficios</h1>
          <p>
            Guappe es la App de delivery que te ayudará a organizar todos tus procesos de delivery. 
          </p>
          <p>
            Obtén lo mejor de la tecnología con la menor tasa del mercado. 
          </p>
          <p>
            Registrate hoy y tendrás <strong>3 meses con CERO comisión</strong>.
          </p>
        </Details>
        <Thumbnail>
          <img src={dev} alt="I’m John and I’m a Backend & Devops engineer!" />
        </Thumbnail>
      </SkillsWrapper>
    </Wrapper>
  );
};
